<template>
  <div v-if="Object.keys(delivery_challan).length !== 0">
    <delivery-challan-from-hub-detail :delivery_challan="delivery_challan"></delivery-challan-from-hub-detail>
    <delivery-challan-to-hub-detail v-if="delivery_challan.delivery_to" :delivery_challan="delivery_challan"></delivery-challan-to-hub-detail>
    <sender-detail :delivery_challan="delivery_challan"></sender-detail>
    <receiver-detail v-if="delivery_challan.receiver" :delivery_challan="delivery_challan"></receiver-detail>
<!--    <recipient-detail v-if="delivery_challan.recipient_name" :delivery_challan="delivery_challan"></recipient-detail>-->
    <bearer-detail v-if="delivery_challan.bearer_name" :delivery_challan="delivery_challan"></bearer-detail>
    <delivery-challan-header-detail :delivery_challan="delivery_challan" :spinning="spinning" :delayTime="delayTime"></delivery-challan-header-detail>
    <delivery-challan-header-status :current="current" :delivery_challan="delivery_challan"></delivery-challan-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-bordered text-center" id="requisition_item_list">
              <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Unit</th>
                <th>Transfer Qty</th>
                <th>Remarks</th>
                <th>Received Qty</th>
                <th>Receiver Comment</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(delivery_challan_item, index) in delivery_challan_items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ delivery_challan_item.challan_item }}</td>
                <td>{{ delivery_challan_item.unit }}</td>
                <td>{{ delivery_challan_item.transfer_qty }}</td>
                <td>{{ delivery_challan_item.remarks || 'N/A' }}</td>
                <td>{{ delivery_challan_item.received_qty || 'N/A' }}</td>
                <td>{{ delivery_challan_item.receiver_comment || 'N/A' }}</td>
                <td>
                  <a-tag color="#f50" v-if="delivery_challan_item.status == '1'">Pending</a-tag>
                  <a-tag color="#87d068" v-if="delivery_challan_item.status == '2'">Full Received</a-tag>
                  <a-tag color="#E0A800" v-if="delivery_challan_item.status == '3'">Partially Received</a-tag>
                  <a-tag color="#f50" v-if="delivery_challan_item.status == '4'">Rejected</a-tag>
                </td>
                <td>
                  <a v-if="delivery_challan_item.status == '3'" :href="delivery_challan_item.delivery_challan_item_picture" target="_blank" download>
                    <img :src="delivery_challan_item.delivery_challan_item_picture" alt="" class="rounded d-block" width="40" height="40"/>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <h5 class="text-center text-secondary" v-if="delivery_challan_items.length === 0">
              <Empty :image="simpleImage"></Empty>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="delivery_challan.delivery_challan_picture">
      <h5>Delivery Challan Picture</h5>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="delivery_challan.delivery_challan_picture_url" target="_blank" download>
                <img :src="delivery_challan.delivery_challan_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import deliveryChallanHeaderDetail from '@/views/deliveryChallan/partial/delivery_challan_header_detail'
import deliveryChallanHeaderStatus from '@/views/deliveryChallan/partial/delivery_challan_header_status'
import deliveryChallanFromHubDetail from '@/views/deliveryChallan/partial/delivery_challan_from_hub_detail'
import deliveryChallanToHubDetail from '@/views/deliveryChallan/partial/delivery_challan_to_hub_detail'
import senderDetail from '@/views/deliveryChallan/partial/sender_detail'
import receiverDetail from '@/views/deliveryChallan/partial/receiver_detail'
import bearerDetail from '@/views/deliveryChallan/partial/bearer_detail'
// import recipientDetail from '@/views/deliveryChallan/partial/recipient_detail'

export default {
  name: 'List',
  components: { Empty, deliveryChallanHeaderDetail, deliveryChallanHeaderStatus, deliveryChallanFromHubDetail, deliveryChallanToHubDetail, senderDetail, receiverDetail, bearerDetail },
  data() {
    return {
      delivery_challan_items: {},
      delivery_challan: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getDeliveryChallanItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getDeliveryChallanItems() {
      const deliveryChallanId = this.$route.params.delivery_challan_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/delivery-challan/view/' + deliveryChallanId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.delivery_challan_items = data.delivery_challan_items
            this.delivery_challan = data.delivery_challan
            this.current = data.max_delivery_challan_status
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
</style>
