<template>
  <div class="col-md-12" v-if="Object.keys(delivery_challan.receiver).length !== 0">
    <h5>Receiver`s Details</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ delivery_challan.receiver.name }}<br>
            <span v-if="delivery_challan.challan_type == 2"><b>Organization: </b> {{ delivery_challan.receiver.organization }}<br></span>
            <span v-if="delivery_challan.challan_type == 2"><b>Phone No: </b> {{ delivery_challan.receiver.phone_no }}</span>
            <span v-if="delivery_challan.challan_type == 1"><b>Designation: </b> {{ delivery_challan.receiver.designation }}<br></span>
            <span v-if="delivery_challan.challan_type == 1"><b>Department: </b> {{ delivery_challan.receiver ? delivery_challan.receiver.department ? delivery_challan.receiver.department.department_name : '' : '' }}</span><br>
          </div>
          <div class="col-sm-6">
            <span  v-if="delivery_challan.challan_type == 1"><b>Employee Id: </b> {{ delivery_challan.receiver.employee_id }}</span><br>
            <span  v-if="delivery_challan.challan_type == 1"><b>Phone No: </b> {{ delivery_challan.receiver.phone_no }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'receiver_detail',
  props: ['delivery_challan'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
