<template>
  <div class="col-md-12" v-if="Object.keys(delivery_challan).length !== 0">
    <h5>Sender`s Details</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ delivery_challan.sender.name }}<br>
            <b>Designation: </b> {{ delivery_challan.sender.designation }}<br>
            <b>Department: </b> {{ delivery_challan.sender.department.department_name }}
          </div>
          <div class="col-sm-6">
            <b>Employee Id: </b> {{ delivery_challan.sender.employee_id }}<br>
            <b>Phone No: </b> {{ delivery_challan.sender.phone_no }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'sender_detail',
  props: ['delivery_challan'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
